import { useNetInfo } from '@react-native-community/netinfo';
import gql from 'graphql-tag';
import Modal from 'react-native-modal';
import React from 'react';
import {
  Animated,
  Dimensions,
  Keyboard,
  Text,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  TextInput,
  View,
  FlatList,
  SafeAreaView,
  Alert
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import QRClear from '../../../assets/QR-code-clear-data';
import QRGet from '../../../assets/QR-code-get-data';
import ScannerSVG from '../../../assets/ScannerSVG';
import { client } from '../../config/apollo/client';
import { useStores } from '../../state/stores/Root/RootStoreContext';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import {
  AnimatedContainer,
  BottomButton,
  BottomButtonText,
  FakeInput,
  Loading,
  MainBottom,
  MainBottomNumber,
  MainContainer,
  MainSubtitle,
  MainText,
  MainTextContainer,
  MainTitle,
  BottomButtonModal,
  QrContainer,
  RowContainer,
  ScanningContainer
} from './style';
import { Header } from '../../components/Header';
import { CloseContainer } from '../Register/style';
import X from '../../../assets/X';
import { Title } from '../Dashboard/style';
import { expo } from '../../../../app.json';
import * as Clipboard from 'expo-clipboard';

export const createScans = gql`
  mutation createScans($scansDataMatrixCodes: [String!]!, $version: String) {
    createScans(scansDataMatrixCodes: $scansDataMatrixCodes, version: $version) {
      dataMatrixCode
      success
      reason
    }
  }
`;

const { width } = Dimensions.get('window');

const Scanner: React.FunctionComponent = props => {
  const [currentIndex, setCurrentIndex] = React.useState<number | undefined>(1);
  const scrollRef = React.useRef(0);
  const fakeInputRef = React.useRef<TextInput>();
  const scrollY = React.useRef(new Animated.Value(0)).current;
  const [isKeyboardVisible, setKeyboardVisible] = React.useState(false);
  const [scanText, setScanText] = React.useState<string>('');
  const [scannedDataFromScanner, setScannedDataFromScanner] = React.useState<string[]>([]);
  const [retrievedScanData, setRetrievedScanData] = React.useState<
    { dataMatrixCode: string; success: boolean; reason: string }[]
  >([]);
  const [loadingScanData, setLoadingScanData] = React.useState(false);
  const [scanErrorMessage, setScanErrorMessage] = React.useState('');
  const [showRetry, setShowRetry] = React.useState(false);
  const [showRetrievedScans, setShowRetrievedScans] = React.useState(false);
  const isFocused = useIsFocused();
  const netInfo = useNetInfo();
  const rootStore = useStores();

  React.useEffect(() => {
    if (isFocused === true) {
      setScanText('');
      setScannedDataFromScanner([]);
      setRetrievedScanData([]);
      (scrollRef.current as any).scrollTo({ x: 0 });
      setCurrentIndex(currentIndex => {
        return 1;
      });
    }
  }, [isFocused]);

  React.useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', e => {
      setKeyboardVisible(true); // or some other action
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', e => {
      setKeyboardVisible(false); // or some other action
    });

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  React.useEffect(() => {
    if (scanText === '') {
      setLoadingScanData(false);
    } else {
      setLoadingScanData(true);
    }
    const timeoutLoading = setTimeout(() => {
      setLoadingScanData(false);

      const lastElementOfArray = scannedDataFromScanner.length - 1;

      if (scanText === '') {
        setShowRetry(false);
      } else if (scannedDataFromScanner.length > 0 && scannedDataFromScanner[lastElementOfArray] !== '') {
        setShowRetry(true);
      } else if (scannedDataFromScanner.length > 0 && scannedDataFromScanner[lastElementOfArray] === '') {
        const scannedDataCopy = [...scannedDataFromScanner];
        if (Platform.OS === 'ios') {
          scannedDataCopy.forEach((element, index) => {
            if (index !== lastElementOfArray) {
              const firstElementSplit = element.slice(0, 34);
              let secondElementSplit = element.slice(34, element.length);
              if (secondElementSplit.substring(0, 3) === '029') {
                secondElementSplit = secondElementSplit.slice(3, element.length);
                scannedDataCopy[index] = `${firstElementSplit}${secondElementSplit}`;
              }

              console.log(secondElementSplit);
              if (secondElementSplit.substring(0, 2) === '29') {
                secondElementSplit = secondElementSplit.slice(2, element.length);
                scannedDataCopy[index] = `${firstElementSplit}${secondElementSplit}`;
              }

              const firstElementSplit2 = element.slice(0, 40);
              let secondElementSplit2 = element.slice(40, element.length);
              if (secondElementSplit2.substring(0, 1) === '“') {
                secondElementSplit2 = secondElementSplit2.slice(1, element.length);
                scannedDataCopy[index] = `${firstElementSplit2}${secondElementSplit2}`;
              }
            }
          });

          setScannedDataFromScanner(scannedDataCopy);

          setShowRetry(
            scannedDataCopy.some((item: String, index: Number) => {
              if (index === lastElementOfArray) {
                return false;
              } else {
                return false;
                return item.length !== 48 && item.length !== 40;
              }
            })
          );
        } else {
          scannedDataCopy.forEach((element, index) => {
            if (index !== lastElementOfArray) {
              const firstElementSplit = element.slice(0, 40);
              let secondElementSplit = element.slice(40, element.length);

              if (secondElementSplit.substring(0, 1) === '#') {
                secondElementSplit = secondElementSplit.slice(1, element.length);
                scannedDataCopy[index] = `${firstElementSplit}${secondElementSplit}`;
              }

              if (index === 0) {
                console.log(element, 'in if');
                if (element.substring(0, 1) !== '(') {
                  scannedDataCopy[index] = `(${element}`;
                }
              }
            }
          });

          setScannedDataFromScanner(scannedDataCopy);

          setShowRetry(
            scannedDataCopy.some((item: String, index: Number) => {
              if (index === lastElementOfArray) {
                return false;
              } else {
                return false;
                return item.length !== 48 && item.length !== 40;
              }
            })
          );
        }
      }
    }, 1000);
    return () => {
      clearTimeout(timeoutLoading);
    };
  }, [scanText]);

  const handleClose = () => {
    setRetrievedScanData([]);
    setShowRetrievedScans(false);
    (scrollRef.current as any).scrollTo({ x: width * 2 });
    setTimeout(() => {
      setCurrentIndex((currentIndex: number | undefined) => {
        if (Platform.OS === 'web') {
          if (currentIndex) {
            if (currentIndex <= 2) {
              return currentIndex + 1;
            } else {
              return 1;
            }
          }
        } else {
          if (currentIndex) {
            return currentIndex + 1;
          }
        }
      });
    }, 100);
  };

  const onChangeText = (text: string) => {
    if (!isKeyboardVisible) {
      // if (retrievedScanData.length > 0) {
      //   setRetrievedScanData([]);
      // }
      setScanText(text);
      if (text !== '') {
        var arr = text.split(/\r?\n/);
        setScannedDataFromScanner(arr);
      }
    }
  };

  const createScansMutation = async (mockData: string[]) => {
    try {
      setScanErrorMessage('');
      const appVersion = expo.version;
      const arrayWithoutLastElement = scannedDataFromScanner.slice(0, scannedDataFromScanner.length - 1);
      const acceptedCharacters = /^[0-9A-Za-z\(\)#]+$/;
      const hasUnacceptedCharacters = !(mockData || arrayWithoutLastElement).every((scannedCode: string) => {
        return !!scannedCode.match(acceptedCharacters);
      });
      // if (hasUnacceptedCharacters) {
      //   setScanErrorMessage(
      //     rootStore.language.language === 'en'
      //       ? 'Invalid codes. Please check that the scanner and keyboard languages are the same and try again.'
      //       : rootStore.language.language === 'fr'
      //       ? 'Codes invalides. Merci de vérifier que la langue du scanner est bien configurée par rapport au téléphone utilisé. Quand cela est fait, vous pouvez à nouveau envoyer les codes du scanner sur le téléphone.'
      //       : 'Ongeldige codes. Controleer of de taal van de scanner correct is ingesteld voor de gebruikte smartphone. Als dit is gebeurd, kunt u de scanner codes opnieuw naar de  smartphone sturen.'
      //   );
      //   setShowRetry(true);
      //   return;
      // }
      console.log({ arrayWithoutLastElement });
      const { data } = (await client.mutate({
        mutation: createScans,
        variables: {
          version: appVersion,
          scansDataMatrixCodes: arrayWithoutLastElement
        },
        refetchQueries: ['getScannedProductTypesInfo']
      })) as any;
      if (data.createScans) {
        console.log({ data }, 'from create scans');
        setRetrievedScanData(data.createScans);
        setShowRetrievedScans(true);
        fakeInputRef?.current?.blur();
        setScanText('');
        setScannedDataFromScanner([]);
        // (scrollRef.current as any).scrollTo({ x: width * 2 });
        // setCurrentIndex((currentIndex: number | undefined) => {
        //   if (currentIndex) {
        //     return currentIndex + 1;
        //   }
        // });
      } else {
        setShowRetry(true);
      }
    } catch (error) {
      console.log(error);
      setShowRetry(true);
    }
  };

  const showReason = (item: any, reason: string) => {
    if (reason === 'product_type_not_found') {
      return rootStore.language.language === 'en'
        ? 'Product type not found'
        : rootStore.language.language === 'fr'
        ? 'Type de produit non trouvé'
        : 'Produkttyp nicht gefunden';
    } else if (reason === 'invalid_scan_code') {
      return rootStore.language.language === 'en'
        ? 'Invalid format'
        : rootStore.language.language === 'fr'
        ? 'Format invalide.'
        : 'Ungültiges Format';
    }
    return item?.dataMatrixCode.substr(-6);
  };

  const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'));

    if (result) {
      const confirmOption = options.find(({ style }) => style !== 'cancel');
      confirmOption && confirmOption.onPress();
    } else {
      const cancelOption = options.find(({ style }) => style === 'cancel');
      cancelOption && cancelOption.onPress();
    }
  };

  const showErrorAlert = (item: any) => {
    if (Platform.OS === 'web') {
      alertPolyfill(
        'Error: ' + showReason(item, item.reason),
        'Code: ' + item.dataMatrixCode + ' . Do you want to copy this code?',
        [
          {
            onPress: () => {
              Clipboard.setStringAsync(item.dataMatrixCode);
            }
          },
          {
            onPress: () => {},
            style: 'cancel'
          }
        ],
        null
      );
    } else {
      Alert.alert(showReason(item, item.reason), item.dataMatrixCode, [
        { text: 'Close', onPress: () => {} },
        {
          text: 'Copy code',
          onPress: () => {
            Clipboard.setStringAsync(item.dataMatrixCode);
          }
        }
      ]);
    }
  };

  return (
    <MainContainer style={Platform.OS === 'web' ? { minWidth: '50%', alignItems: 'center', alignSelf: 'center' } : {}}>
      <AnimatedContainer>
        <RowContainer>
          <Animated.View
            style={{
              height: 5,
              marginRight: 5,
              width: 28,
              borderRadius: 10,
              backgroundColor: '#fff'
            }}
          />
          <Animated.View
            style={{
              height: 5,
              width: 28,
              marginRight: 5,
              borderRadius: 10,
              backgroundColor: Platform.OS === 'web' && currentIndex >= 2 ? '#fff' : 'rgba(255,255,255,0.2)'
            }}
          />
          <Animated.View
            style={{
              height: 5,
              width: 28,
              borderRadius: 10,
              backgroundColor: Platform.OS === 'web' && currentIndex >= 3 ? '#fff' : 'rgba(255,255,255,0.2)'
            }}
          />
          <Animated.View
            style={{
              position: 'absolute',
              right: 28,
              height: 5,
              marginRight: 5,
              width: 28,
              borderRadius: 10,
              backgroundColor: '#fff',
              zIndex: 999,
              opacity: scrollY.interpolate({
                inputRange: [0, width],
                outputRange: [0, 1],
                extrapolate: 'clamp'
              })
            }}
          />
          <Animated.View
            style={{
              position: 'absolute',
              right: -5,
              height: 5,
              marginRight: 5,
              width: 28,
              borderRadius: 10,
              backgroundColor: '#fff',
              zIndex: 999,
              opacity: scrollY.interpolate({
                inputRange: [0, width, width * 2],
                outputRange: [0, 0, 1],
                extrapolate: 'clamp'
              })
            }}
          />
        </RowContainer>
      </AnimatedContainer>
      <Animated.ScrollView
        ref={scrollRef}
        keyboardShouldPersistTaps="always"
        // Disable scroll with:
        // scrollEnabled={false}
        // If scroll enabled calculate currentIndex
        onMomentumScrollEnd={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
          const index =
            Platform.OS === 'web' ? currentIndex + 1 : Math.round(event.nativeEvent.contentOffset.x / width) + 1;
          if (index === 2) {
            fakeInputRef?.current?.focus();
          } else {
            fakeInputRef?.current?.blur();
          }
          setScanText('');
          setScannedDataFromScanner([]);
          setRetrievedScanData([]);
          setCurrentIndex(Platform.OS === 'web' ? (index === 4 ? 1 : index) : index);
        }}
        horizontal
        snapToOffsets={[width, width * 2, width * 3]}
        decelerationRate="fast"
        showsHorizontalScrollIndicator={false}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollY } } }], {
          useNativeDriver: true
        })}
        scrollEventThrottle={16}
        style={{ flex: 1, position: 'absolute', marginTop: 15 }}
      >
        {((Platform.OS === 'web' && currentIndex === 1) || Platform.OS !== 'web') && (
          <MainTextContainer>
            <MainText>
              <MainTitle>{rootStore.language.translate.MOBILE.scannerFirstSlideTitle}</MainTitle>

              <MainSubtitle>{rootStore.language.translate.MOBILE.scannerFirstSlideSubTitle}</MainSubtitle>
              <QrContainer>
                <ScannerSVG />
              </QrContainer>
            </MainText>
          </MainTextContainer>
        )}

        {((Platform.OS === 'web' && currentIndex === 2) || Platform.OS !== 'web') && (
          <MainTextContainer>
            <MainText>
              <MainTitle>
                {rootStore.language.language === 'en'
                  ? 'Connect scanner and scan QR Code'
                  : 'Connectez le scanner et scannez le code QR'}
              </MainTitle>

              <MainSubtitle>{rootStore.language.translate.MOBILE.scannerSecondSlideSubTitle}</MainSubtitle>

              <QrContainer>
                <QRGet />
              </QrContainer>

              <ScanningContainer>
                {!isKeyboardVisible && scanText !== '' ? (
                  loadingScanData ? (
                    <View style={{ marginTop: 15 }}>
                      <Loading />
                      <MainBottomNumber>{scannedDataFromScanner.length - 1}</MainBottomNumber>
                      <MainBottom>{rootStore.language.translate.MOBILE.scannerInputing}</MainBottom>
                    </View>
                  ) : (
                    <View>
                      <MainBottomNumber>{scannedDataFromScanner.length - 1}</MainBottomNumber>
                      <MainBottom>{rootStore.language.translate.MOBILE.scannerFinished}</MainBottom>
                      {showRetry ? (
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                          <AntDesign name="closecircle" size={24} color="red" />
                          <Text style={{ marginLeft: 10, color: '#8c8c8c' }}>
                            {scanErrorMessage || 'Something went wrong. '}
                          </Text>
                        </View>
                      ) : null}
                    </View>
                  )
                ) : (
                  <View style={{ opacity: 0 }}>
                    <MainBottomNumber>{scannedDataFromScanner.length - 1}</MainBottomNumber>
                    <MainBottom>{rootStore.language.translate.MOBILE.scannerFinished}</MainBottom>
                  </View>
                )}
              </ScanningContainer>
            </MainText>

            <FakeInput
              ref={fakeInputRef}
              multiline
              onChangeText={onChangeText}
              value={scanText}
              ena
              showSoftInputOnFocus={false}
            />
          </MainTextContainer>
        )}
        {((Platform.OS === 'web' && currentIndex === 3) || Platform.OS !== 'web') && (
          <MainTextContainer>
            <MainText>
              <MainTitle>{rootStore.language.translate.MOBILE.scannerThirdSlideTitle}</MainTitle>
              <MainSubtitle>{rootStore.language.translate.MOBILE.scannerThirdSlideSubTitle}</MainSubtitle>
              <QrContainer>
                <QRClear />
              </QrContainer>
            </MainText>
          </MainTextContainer>
        )}
      </Animated.ScrollView>

      <Modal
        // isVisible={currentIndex === 2 && retrievedScanData.length > 0}

        isVisible={showRetrievedScans}
        useNativeDriver={true}
        style={{
          margin: 0,
          marginTop: 135,
          backgroundColor: '#fff',
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20
        }}
        hideModalContentWhileAnimating={true}
        onCancelPressed={() => {
          setRetrievedScanData([]);
          setShowRetrievedScans(false);
          (scrollRef.current as any).scrollTo({ x: width * 2 });
          setCurrentIndex((currentIndex: number | undefined) => {
            if (Platform.OS === 'web') {
              if (currentIndex) {
                if (currentIndex <= 2) {
                  return currentIndex + 1;
                } else {
                  return 1;
                }
              }
            } else {
              if (currentIndex) {
                return currentIndex + 1;
              }
            }
          });
        }}
        onBackButtonPressed={() => {
          setRetrievedScanData([]);
          setShowRetrievedScans(false);
          (scrollRef.current as any).scrollTo({ x: width * 2 });
          setCurrentIndex((currentIndex: number | undefined) => {
            if (Platform.OS === 'web') {
              if (currentIndex) {
                if (currentIndex <= 2) {
                  return currentIndex + 1;
                } else {
                  return 1;
                }
              }
            } else {
              if (currentIndex) {
                return currentIndex + 1;
              }
            }
          });
        }}
        onModalHide={() => {
          setRetrievedScanData([]);
          setShowRetrievedScans(false);
          (scrollRef.current as any).scrollTo({ x: width * 2 });
          setTimeout(() => {
            setCurrentIndex((currentIndex: number | undefined) => {
              if (Platform.OS === 'web') {
                if (currentIndex) {
                  if (currentIndex <= 2) {
                    return currentIndex + 1;
                  } else if (currentIndex === 3) {
                    return currentIndex;
                  } else {
                    return 1;
                  }
                }
              } else {
                if (currentIndex) {
                  return currentIndex + 1;
                }
              }
            });
          }, 100);
        }}
        onBackdropPress={() => {
          setRetrievedScanData([]);
          setShowRetrievedScans(false);
          (scrollRef.current as any).scrollTo({ x: width * 2 });
          setCurrentIndex((currentIndex: number | undefined) => {
            if (Platform.OS === 'web') {
              if (currentIndex) {
                if (currentIndex <= 2) {
                  return currentIndex + 1;
                } else {
                  return 1;
                }
              }
            } else {
              if (currentIndex) {
                return currentIndex + 1;
              }
            }
          });
        }}
        backdrop
      >
        <SafeAreaView style={{ flex: 1, marginVertical: 20 }}>
          <Title style={{ textAlign: 'center', marginBottom: 10 }}>Codes scannés</Title>
          <CloseContainer onPress={handleClose} hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}>
            <X />
          </CloseContainer>
          <FlatList
            contentContainerStyle={{ marginTop: 20 }}
            data={retrievedScanData}
            renderItem={({ item, index }) => (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 20,
                  marginHorizontal: 30
                }}
              >
                {item.success ? (
                  <AntDesign name="checkcircle" size={24} color="green" />
                ) : (
                  <AntDesign name="closecircle" size={24} color="red" />
                )}
                <Text style={{ marginLeft: 10 }}>
                  {item.success && (
                    <View>
                      <Text style={{ fontWeight: 'bold', alignSelf: 'center', fontSize: 16 }}>
                        {item?.dataMatrixCode.substr(-6)}
                      </Text>
                    </View>
                  )}
                  {item.success === false && (
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ fontWeight: '500', alignSelf: 'center', marginRight: 8, fontSize: 16 }}>
                        {showReason(item, item.reason)}
                      </Text>
                      {
                        <Ionicons
                          name="md-information-circle-outline"
                          size={24}
                          color="black"
                          onPress={() => {
                            showErrorAlert(item);
                          }}
                        />
                      }
                    </View>
                  )}
                  {/* {retrievedDataItem?.success ? 'is ok' : 'failed'} */}
                </Text>
              </View>
            )}
            keyExtractor={(item, index) => index.toString()}
            // ListFooterComponent={() => {
            //   return (
            //     <View style={{ flex: 1, backgroundColor: 'red', justifyContent: 'flex-end' }}>
            //       <BottomButtonModal
            //         disabled={isKeyboardVisible || netInfo?.isConnected?.toString() === 'false'}
            //         onPress={() => {
            //           setRetrievedScanData([]);
            //           (scrollRef.current as any).scrollTo({ x: width * 2 });
            //           setCurrentIndex((currentIndex: number | undefined) => {
            //             if (currentIndex) {
            //               return currentIndex + 1;
            //             }
            //           });
            //         }}
            //       >
            //         <BottomButtonText>{rootStore.language.translate.MOBILE.scannerButtonText}</BottomButtonText>
            //       </BottomButtonModal>
            //     </View>
            //   );
            // }}
          />

          {/* <BottomButton
            disabled={isKeyboardVisible || netInfo?.isConnected?.toString() === 'false'}
            onPress={() => {
              setRetrievedScanData([]);
              (scrollRef.current as any).scrollTo({ x: width * 2 });
              setCurrentIndex((currentIndex: number | undefined) => {
                if (currentIndex) {
                  return currentIndex + 1;
                }
              });
            }}
          >
            <BottomButtonText>{rootStore.language.translate.MOBILE.scannerButtonText}</BottomButtonText>
          </BottomButton> */}
        </SafeAreaView>
      </Modal>

      {(Platform.OS === 'web' && currentIndex !== 4 && scannedDataFromScanner.length === 0) ||
      (Platform.OS !== 'web' && currentIndex !== 2) ? (
        <BottomButton
          onPress={() => {
            setCurrentIndex((currentIndex: number | undefined) => {
              if (currentIndex === 1) {
                (scrollRef.current as any).scrollTo({ x: width });
                setTimeout(() => {
                  fakeInputRef?.current?.focus();
                }, 400);
                return currentIndex + 1;
              }
              if (Platform.OS === 'web') {
                if (currentIndex === 2) {
                  (scrollRef.current as any).scrollTo({ x: 0 });
                  // props.navigation.navigate('Dashboard');
                  return 3;
                }
                if (currentIndex === 3) {
                  (scrollRef.current as any).scrollTo({ x: 0 });
                  // props.navigation.navigate('Dashboard');
                  return 1;
                }
              }
              if (currentIndex === 4) {
                (scrollRef.current as any).scrollTo({ x: 0 });
                // props.navigation.navigate('Dashboard');
                return 1;
              }
            });
          }}
          offline={netInfo?.isConnected?.toString() === 'false'}
          style={
            Platform.OS === 'web'
              ? {
                  width: '80%',
                  position: 'relative',
                  bottom: 0,
                  right: 0,
                  left: 0
                }
              : {}
          }
        >
          {console.log(netInfo?.isConnected?.toString() === 'false')}
          <BottomButtonText>
            {currentIndex === 4
              ? rootStore.language.translate.MOBILE.scannerButtonFinished
              : netInfo?.isConnected?.toString() === 'false'
              ? 'Déconnecté'
              : rootStore.language.translate.MOBILE.scannerButtonText}
          </BottomButtonText>
        </BottomButton>
      ) : null}

      {currentIndex === 2 &&
      scannedDataFromScanner.length - 1 > 0 &&
      !loadingScanData &&
      !showRetry &&
      !isKeyboardVisible ? (
        <BottomButton
          disabled={isKeyboardVisible || netInfo?.isConnected?.toString() === 'false'}
          onPress={() => {
            createScansMutation();
          }}
          offline={netInfo?.isConnected?.toString() === 'false'}
          style={
            Platform.OS === 'web'
              ? {
                  width: '80%',
                  position: 'relative',
                  bottom: 0,
                  right: 0,
                  left: 0
                }
              : {}
          }
        >
          <BottomButtonText>
            {netInfo?.isConnected?.toString() === 'false'
              ? 'Déconnecté'
              : rootStore.language.translate.MOBILE.scannerButtonText}
          </BottomButtonText>
        </BottomButton>
      ) : null}

      {currentIndex === 2 && scanText !== '' && !loadingScanData && showRetry && !isKeyboardVisible ? (
        <BottomButton
          disabled={isKeyboardVisible}
          onPress={() => {
            setScanText('');
            setScannedDataFromScanner([]);
            setRetrievedScanData([]);
            Platform.OS === 'web' && fakeInputRef?.current?.focus();
          }}
          style={
            Platform.OS === 'web'
              ? {
                  width: '80%',
                  position: 'relative',
                  bottom: 0,
                  right: 0,
                  left: 0
                }
              : {}
          }
        >
          <BottomButtonText>
            Invalid datamatrix. {rootStore.language.translate.MOBILE.scannerButtonRetry}
          </BottomButtonText>
        </BottomButton>
      ) : null}
    </MainContainer>
  );
};

export default Scanner;
